export default class InputMask{

    constructor(input, options,  onComplete){
        this.input = input;
        this.mask = options.mask;
        this.placeholder = options.placeholder;
        this.autoPlaceholder = options.autoPlaceholder;
        this.onComplete = onComplete;

        this.isAttached = false;
        this.attached();
    }

    attached(){
        if(this.isAttached) return;
        if(document.body.contains(this.input) == false) return;

        if (!this.autoPlaceholder) {
            this.autoPlaceholder = 'polite';
        }

        this.attachUI();
        this.attachEvent();
        this.isAttached = true
    }

    attachUI(){
        if(this.autoPlaceholder == 'polite'){
            if(!this.input.placeholder){
                this.input.placeholder = this.placeholder;
            }
        }

        if(this.autoPlaceholder == 'aggressive'){
            this.input.placeholder = this.placeholder;
        }
        
    }

    attachEvent(){
        this.input.addEventListener('input', this.handleMask);
    }

    detachEvent() {
        this.input.removeEventListener('input', this.handleInput);
    }
    
    handleMask = (event) => {
        let j = 0;
        let _this = this;
        let maskedValue = '';
        let value = event.target.value;

        const inputChars = this.mask.split('').filter(char => char === '#');
        value = value.replace(/[^0-9]/g, '');

        for (let i = 0; i < _this.mask.length && j < value.length; i++) {
            if (_this.mask[i] === '#') {
                maskedValue += value[j];
                j++;
            } else {
                maskedValue += _this.mask[i];
            }
        }
        event.target.value = maskedValue;
        if (j === inputChars.length && typeof _this.onComplete === 'function') {
            _this.onComplete(event.target.value);
        }
    }

    detach() {
        if (!this.isAttached) return;
    
        this.detachEvent();
        this.isAttached = false;
    }
}