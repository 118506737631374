export default class Tab {
    constructor(tab) {
        this.tab = tab
        this.isAttached = false;
        this.attached();
    }

    attached() {
        if (this.isAttached) return;
        if (document.body.contains(document.querySelector(this.tab)) == false) return;

        this.attachEvent();
        this.restoreActiveTab();
        this.isAttached = true
    }

    attachEvent() {
        // Get all the tabs
        const tabs = document.querySelectorAll(this.tab);

        // Loop through each tab and add an event listener to it
        tabs.forEach(tab => {
            tab.addEventListener('click', () => {
                // Get the id of the tab panel associated with this tab
                const tabPanelId = tab.getAttribute('data-tab');

                // Hide all the tab panels
                document.querySelectorAll('.tab-panel').forEach(tabPanel => {
                    tabPanel.classList.remove('active');
                });

                // Show the tab panel associated with this tab
                document.getElementById(tabPanelId).classList.add('active');

                // Remove the "active" class from all tabs
                tabs.forEach(tab => {
                    tab.classList.remove('active');
                });

                // Add the "active" class to this tab
                tab.classList.add('active');

                // save the active tab
                localStorage.setItem('activeTab', tabPanelId);
            });
        });
    }

    restoreActiveTab() {
        const activeTabId = localStorage.getItem('activeTab');

        if (activeTabId) {
            const activeTab = document.getElementById(activeTabId);
            const activeTabMenu = document.querySelector(`a[data-tab="${activeTab.id}"]`);

            if (activeTab && activeTabMenu) {
                activeTab.classList.add('active');
                activeTabMenu.classList.add('active');
            }
        } else {
            const tabs = document.querySelectorAll(this.tab);
            if (tabs.length > 0) {
                const firstTabMenu = tabs[0];
                const firstTab = document.getElementById(firstTabMenu.getAttribute('data-tab'));

                if (firstTab && firstTabMenu) {
                    firstTab.classList.add('active')
                    firstTabMenu.classList.add('active');

                    localStorage.setItem('activeTab', firstTab.id);
                }
            }

        }

    }
}