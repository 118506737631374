
export default function debounce(func, timeout = 300) {
    const spinner = document.querySelector('.spinner');

    let timer;
    return (...args) => {
        if (!timer) {
            if (spinner) {
                spinner.style.display = 'inline-block'; 
              }
            func.apply(this, args);
        }
        else {
            // Log to console while waiting
            // console.log("Waiting for debounce timeout...");
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (spinner) {
                spinner.style.display = 'none';
            }
            timer = undefined;
        }, timeout);
    };
}
